<template>
  <div>
    <Navbar />

    <div class="d-md-flex justify-content-center my-5">
      <div class="bg-light border form-width mx-md-0 mx-auto">
        <form
          action=""
          method="post"
          enctype="multipart/form-data"
          class="p-5"
          @submit.prevent="updateData"
          :disabled="disableEdit"
        >
          <div class="mb-4 border-bottom">
            <h4 class="fw-semibold">Informasi Surat</h4>
          </div>
          <div class="mb-2 position-relative">
            <input
              type="date"
              name="tanggal_surat"
              id="tanggal_surat"
              class="form-control"
              v-model="tanggalSurat"
              data-toggle="tooltip"
              title="Tanggal Surat"
              :disabled="disableEdit"
              required
            />
            <b-icon-calendar class="icon"></b-icon-calendar>
          </div>
          <div class="mb-2 position-relative">
            <input
              type="date"
              name="tanggal_entry"
              id="tanggal_entry"
              class="form-control"
              v-model="tanggalEntry"
              data-toggle="tooltip"
              title="Tanggal Entry"
              :disabled="disableEdit"
              required
            />
            <b-icon-calendar class="icon"></b-icon-calendar>
          </div>
          <div class="mb-2">
            <select
              name="jenis_surat"
              id="jenis_surat"
              class="form-select"
              v-model="jenisSurat"
              data-toggle="tooltip"
              title="Jenis Surat"
              :disabled="disableEdit"
              required
            >
              <option>Perubahan Data Kopdit</option>
              <option>Revisi Kebijakan Teknik</option>
              <option>Perubahan NIK/KTP</option>
              <option>Pembatalan Polis</option>
              <option>Surat Umum</option>
            </select>
          </div>
          <div class="mb-2 position-relative">
            <input
              type="text"
              name="asal_surat"
              id="asal_surat"
              class="form-control"
              v-model="asalSurat"
              placeholder="Asal Surat"
              data-toggle="tooltip"
              title="Asal Surat"
              :disabled="disableEdit"
              required
            />
            <b-icon-person class="icon"></b-icon-person>
          </div>
          <div class="mb-2 position-relative">
            <input
              type="text"
              name="keterangan"
              id="keterangan"
              class="form-control"
              v-model="keterangan"
              placeholder="Keterangan"
              data-toggle="tooltip"
              title="Keterangan"
              :disabled="disableEdit"
              required
            />
            <b-icon-info class="icon"></b-icon-info>
          </div>
          <div class="mb-2 position-relative">
            <input
              type="text"
              name="tag"
              id="tag"
              class="form-control"
              v-model="tag"
              placeholder="Tag"
              data-toggle="tooltip"
              title="Tag"
              :disabled="disableEdit"
              required
            />
            <b-icon-tag class="icon"></b-icon-tag>
          </div>
          <div :hidden="hideSubmitBtn" class="mt-3">
            <div class="d-flex gap-2">
              <button
                type="submit"
                name="submit"
                class="btn btn-success btn-sm"
              >
                Simpan
              </button>
              <div
                class="p-1 pointer bg-secondary border rounded-2 text-white"
                @click="enableEdit"
              >
                Cancel
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <span
              class="p-1 pointer text-success"
              :hidden="hideEditBtn"
              @click="enableEdit"
            >
              <b-icon-pen></b-icon-pen> Edit
            </span>
          </div>
        </form>
      </div>
      <div class="bg-light border form-width mx-md-0 mx-auto">
        <div class="p-5">
          <div class="mb-4 border-bottom">
            <h4 class="fw-semibold">Dokumen</h4>
          </div>
          <div v-if="dokumen === 'Dokumen tidak ditemukan'">
            <p class="blockquote-footer fst-italic mt-2">
              Tidak ada dokumen yang diupload.
            </p>
          </div>
          <div v-else v-for="(item, idx) in dokumen" :key="idx">
            <div class="row bg-primary text-white mb-2 p-1 rounded-1">
              <div class="col-11">
                <a
                  :href="
                    'https://docs.google.com/viewer?url=' +
                    lihatDokumen +
                    item.id_surat +
                    '/' +
                    item.nama_file
                  "
                  target="_blank"
                  class="text-white text-decoration-none"
                  data-toggle="tooltip"
                  title="Lihat Dokumen"
                >
                  {{ item.nama_file }}
                </a>
              </div>
              <div class="col-1">
                <div
                  @click="deleteDokumen(item.nama_file)"
                  class="d-flex justify-content-center pointer"
                  data-toggle="tooltip"
                  title="Hapus dokumen"
                >
                  x
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button class="btn btn-sm text-success" @click="tambahDokumen">
              + Tambah Dokumen
            </button>
          </div>
          <form
            action=""
            method="post"
            enctype="multipart/form-data"
            @submit.prevent="uploadFiles"
            class="mt-2"
            :hidden="hideFormUpload"
          >
            <input
              type="file"
              name="files[]"
              id="file"
              class="form-control"
              multiple
              ref="files"
            />
            <div class="d-flex gap-2 mt-2">
              <button
                type="submit"
                name="submit"
                class="btn btn-sm btn-success"
              >
                Upload
              </button>
              <div
                class="p-1 pointer bg-secondary border rounded-2 text-white"
                @click="tambahDokumen"
              >
                Cancel
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";

export default {
  name: "UpdateDataView",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      id: null, // id sudah bernilai sesuai param
      tanggalSurat: "",
      tanggalEntry: "",
      jenisSurat: "",
      asalSurat: "",
      keterangan: "",
      tag: "",
      hideSubmitBtn: true,
      hideEditBtn: false,
      disableEdit: true,
      // DOKUMEN
      dokumen: "",
      lihatDokumen: `https://restfulapisurat.sayo.my.id/files/`,
      hideFormUpload: true,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getOldData();
  },
  methods: {
    goToPage() {
      const isAuthenticated = localStorage.getItem("isAuthenticated");
      if (isAuthenticated !== "true") {
        alert("Anda belum login.");
        this.$router.push("/login");
      }
    },
    getOldData() {
      axios
        .get(
          `https://restfulapisurat.sayo.my.id/api/api_tampil_byid.php?id=${this.id}`
        )
        .then((res) => {
          this.tanggalSurat = res.data[0].tanggal_surat;
          this.tanggalEntry = res.data[0].tanggal_entry;
          this.jenisSurat = res.data[0].jenis_surat;
          this.asalSurat = res.data[0].asal_surat;
          this.keterangan = res.data[0].keterangan;
          this.tag = res.data[0].tag;
          // this.namaDokumen = res.data[0].dokumen;
        })
        .catch((err) => console.log(err));
    },
    async updateData() {
      let formData = new FormData();
      formData.append("id", this.id);
      formData.append("tanggal_surat", this.tanggalSurat);
      formData.append("tanggal_entry", this.tanggalEntry);
      formData.append("jenis_surat", this.jenisSurat);
      formData.append("asal_surat", this.asalSurat);
      formData.append("keterangan", this.keterangan);
      formData.append("tag", this.tag);
      // formData.append("dokumen", this.dokumen);

      try {
        const res = await axios.post(
          "https://restfulapisurat.sayo.my.id/api/api_edit.php",
          formData
        );
        if (res.data.status === true) {
          alert(res.data.message);
          this.enableEdit();
        } else {
          console.log(res.data);
        }
      } catch (err) {
        console.error(err);
      }
    },
    enableEdit() {
      this.disableEdit = !this.disableEdit;
      this.hideSubmitBtn = !this.hideSubmitBtn;
      this.hideEditBtn = !this.hideEditBtn;
    },
    getDokumen() {
      axios
        .get(
          `https://restfulapisurat.sayo.my.id/api/dokumen/api_get_dokumen.php?id_surat=${this.id}`
        )
        .then((res) => {
          this.dokumen = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteDokumen(fileName) {
      const confirm = window.confirm("Anda yakin ingin menghapus dokumen ini?");
      if (confirm) {
        axios
          .delete(
            `https://restfulapisurat.sayo.my.id/api/dokumen/api_delete_dokumen.php?id_surat=${this.id}&nama_file=${fileName}`
          )
          .then(() => {
            alert("Dokumen berhasil dihapus.");
            this.getDokumen();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async uploadFiles() {
      let formData = new FormData();

      formData.append("id_surat", this.id);
      const files = this.$refs.files.files;
      for (let i = 0; i < files.length; i++) {
        formData.append("files[]", files[i]);
      }

      try {
        const res = await axios.post(
          "https://restfulapisurat.sayo.my.id/api/dokumen/api_upload_dokumen.php",
          formData
        );
        if (res.data.success) {
          alert("File berhasil diupload.");
          this.getDokumen();
          this.tambahDokumen();
        } else {
          alert("Gagal mengupload data.");
        }
      } catch (err) {
        console.log(err);
      }
    },
    tambahDokumen() {
      this.hideFormUpload = !this.hideFormUpload;
    },
  },
  mounted() {
    this.goToPage();
    this.getDokumen();
  },
};
</script>
