<template>
  <div class="bg-success shadow-lg container-fluid">
    <h3 class="p-md-4 p-2 text-light d-flex justify-content-start">
      <router-link
        to="/"
        class="text-decoration-none text-light"
        data-toggle="tooltip"
        title="Kembali ke Dashboard."
        ><b-icon-arrow-left-circle
          :hidden="hideArrow"
        ></b-icon-arrow-left-circle>
        Dokumentasi Surat</router-link
      >
    </h3>
  </div>
</template>

<script>
export default {
  name: "NavbarComponent",
  props: {
    hideArrow: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
