<template>
  <div>
    <Navbar :hideArrow="hideArrow" />
    <section class="m-sm-5 p-5" style="min-height: 65vh">
      <div class="container-fluid h-custom">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-md-9 col-lg-6 col-xl-5">
            <img src="/img/login.png" class="img-fluid" alt="login" />
          </div>
          <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <form action="" method="post" @submit.prevent="login">
              <div class="form-outline mb-4">
                <input
                  type="username"
                  id="username"
                  class="form-control form-control-lg"
                  placeholder="Masukkan username"
                  v-model="username"
                  required
                />
              </div>

              <div class="form-outline mb-3">
                <input
                  type="password"
                  id="password"
                  class="form-control form-control-lg"
                  placeholder="Masukkan password"
                  v-model="password"
                  required
                />
              </div>

              <div class="text-center text-lg-start mt-4 pt-2">
                <button
                  type="submit"
                  name="submit"
                  class="btn btn-success"
                  style="padding-left: 2.5rem; padding-right: 2.5rem"
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";

export default {
  name: "LoginView",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      hideArrow: true,
      username: "",
      password: "",
    };
  },
  methods: {
    async login() {
      let formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);

      try {
        const res = await axios.post(
          "https://restfulapisurat.sayo.my.id/api/api_login.php",
          formData
        );
        if (res.data.status === true) {
          localStorage.setItem("isAuthenticated", true);
          alert(res.data.message);
          this.$router.push("/");
        } else {
          alert(res.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    goToPage() {
      const isAuthenticated = localStorage.getItem("isAuthenticated");
      if (isAuthenticated === "true") {
        this.$router.push("/");
      }
    },
  },
  mounted() {
    this.goToPage();
  },
};
</script>
