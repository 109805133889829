<template>
  <footer>
    <hr />
    <p class="d-flex justify-content-center">
      &copy; 2023 - PT Sayo Teknologi Indonesia
    </p>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>
