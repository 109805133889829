import Vue from "vue";
import VueRouter from "vue-router";
import DashboardView from "../views/DashboardView.vue";
import AddDataView from "../views/AddDataView.vue";
import UpdateDataView from "@/views/UpdateDataView.vue";
import LoginView from "@/views/LoginView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: DashboardView,
  },
  {
    path: "/add-data",
    name: "add-data",
    component: AddDataView,
  },
  {
    path: "/update-data/:id",
    name: "update-data",
    component: UpdateDataView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
