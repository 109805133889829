<template>
  <div>
    <Navbar />

    <div class="d-md-flex justify-content-center my-5">
      <div class="bg-light border form-width mx-md-0 mx-auto">
        <form
          action=""
          method="post"
          enctype="multipart/form-data"
          @submit.prevent="addData"
          class="p-5"
          :disabled="isSubmitting"
        >
          <div class="mb-4 border-bottom">
            <h4 class="fw-semibold">Isi Informasi Surat</h4>
          </div>
          <div class="mb-2 position-relative">
            <input
              type="text"
              onfocus="(this.type='date')"
              placeholder="Tanggal Surat"
              name="tanggal_surat"
              id="tanggal_surat"
              class="form-control"
              v-model="tanggalSurat"
              data-toggle="tooltip"
              title="Masukkan tanggal surat."
              required
              :disabled="isSubmitting"
            />
            <b-icon-calendar class="icon"></b-icon-calendar>
          </div>
          <div class="mb-2 position-relative">
            <input
              type="text"
              onfocus="(this.type='date')"
              placeholder="Tanggal Entry"
              name="tanggal_entry"
              id="tanggal_entry"
              class="form-control"
              v-model="tanggalEntry"
              data-toggle="tooltip"
              title="Masukkan tanggal entry."
              required
              :disabled="isSubmitting"
            />
            <b-icon-calendar class="icon"></b-icon-calendar>
          </div>
          <div class="mb-2">
            <select
              name="jenis_surat"
              id="jenis_surat"
              class="form-select"
              v-model="jenisSurat"
              required
              :disabled="isSubmitting"
            >
              <option value="" disabled selected>Pilih Jenis Surat</option>
              <option>Perubahan Data Kopdit</option>
              <option>Revisi Kebijakan Teknik</option>
              <option>Perubahan NIK/KTP</option>
              <option>Pembatalan Polis</option>
              <option>Surat Umum</option>
            </select>
          </div>
          <div class="mb-2 position-relative">
            <input
              type="text"
              name="asal_surat"
              id="asal_surat"
              class="form-control"
              v-model="asalSurat"
              placeholder="Asal Surat"
              required
              :disabled="isSubmitting"
            />
            <b-icon-person class="icon"></b-icon-person>
          </div>
          <div class="mb-2 position-relative">
            <input
              type="text"
              name="keterangan"
              id="keterangan"
              class="form-control"
              v-model="keterangan"
              placeholder="Keterangan"
              required
              :disabled="isSubmitting"
            />
            <b-icon-info class="icon"></b-icon-info>
          </div>
          <div class="mb-2 position-relative">
            <input
              type="text"
              name="tag"
              id="tag"
              class="form-control"
              v-model="tag"
              placeholder="Tag"
              required
              :disabled="isSubmitting"
            />
            <b-icon-tag class="icon"></b-icon-tag>
          </div>
          <button
            type="submit"
            name="submit"
            class="btn btn-success btn-sm mt-3"
            :disabled="isSubmitting"
          >
            Simpan
          </button>
        </form>
      </div>
      <div class="bg-light border form-width mx-md-0 mx-auto">
        <form
          action=""
          method="post"
          enctype="multipart/form-data"
          @submit.prevent="uploadFiles"
          class="p-5"
          :disabled="isSubmittingFile"
        >
          <div class="mb-4 border-bottom">
            <h4 class="fw-semibold">Upload Dokumen</h4>
          </div>
          <div class="mb-2">
            <input
              type="file"
              name="files[]"
              id="file"
              class="form-control"
              multiple
              ref="files"
              required
              :disabled="isSubmittingFile"
            />
          </div>
          <p v-if="info" class="blockquote-footer mt-2">
            Isi Form dan Simpan terlebih dulu.
          </p>
          <button
            type="submit"
            name="submit"
            class="btn btn-success mt-3 w-100"
            :disabled="isSubmittingFile"
          >
            Upload dan kembali ke Dashboard
          </button>
        </form>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "AddDataView",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      tanggalSurat: "",
      tanggalEntry: "",
      jenisSurat: "",
      asalSurat: "",
      keterangan: "",
      tag: "",
      isSubmitting: false,
      isSubmittingFile: true,
      id: "", // id muncul ketika formData sudah disubmit
      // dokumen: null,
      info: true,
    };
  },
  methods: {
    goToPage() {
      const isAuthenticated = localStorage.getItem("isAuthenticated");
      if (isAuthenticated !== "true") {
        alert("Anda belum login.");
        this.$router.push("/login");
      }
    },
    async addData() {
      let formData = new FormData();
      formData.append("tanggal_surat", this.tanggalSurat);
      formData.append("tanggal_entry", this.tanggalEntry);
      formData.append("jenis_surat", this.jenisSurat);
      formData.append("asal_surat", this.asalSurat);
      formData.append("keterangan", this.keterangan);
      formData.append("tag", this.tag);

      try {
        const res = await axios.post(
          "https://restfulapisurat.sayo.my.id/api/api_tambah.php",
          formData
        );
        if (res.data[0].status === "SUCCESS") {
          alert(res.data[0].message);
          this.id = res.data[0].id;
        } else {
          alert(res.data[0].message);
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.info = false;
        this.isSubmitting = true;
        this.isSubmittingFile = false;
      }
    },
    async uploadFiles() {
      let formData = new FormData();

      formData.append("id_surat", this.id);
      const files = this.$refs.files.files;
      for (let i = 0; i < files.length; i++) {
        formData.append("files[]", files[i]);
      }

      try {
        const res = await axios.post(
          "https://restfulapisurat.sayo.my.id/api/dokumen/api_upload_dokumen.php",
          formData
        );
        if (res.data.success) {
          alert("File berhasil diupload");
          this.$router.push("/");
        } else {
          alert("Isi dan save form terlebih dulu");
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.goToPage();
  },
};
</script>
