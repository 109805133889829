<template>
  <div class="container-fluid">
    <div class="row bg-success shadow-lg">
      <div class="col-md-4 col-6 p-md-4 p-2 text-light">
        <h3 class="d-flex justify-content-start">Dokumentasi Surat</h3>
      </div>
      <div class="col-md-4 p-md-4 d-none d-md-block">
        <div class="d-flex gap-2 justify-content-end">
          <router-link
            to="/add-data"
            class="text-light text-decoration-none p-2"
            ><img
              src="/img/plus.png"
              alt="plus"
              data-toggle="tooltip"
              title="Tambah Data"
          /></router-link>
          <form action="" class="d-flex gap-2">
            <input
              type="text"
              name="search"
              size="30"
              placeholder="Masukkan keyword pencarian..."
              class="form-control"
              v-model="searchQuery"
            />
            <button
              type="submit"
              name="search"
              class="btn btn-light btn-sm"
              @click="searchData"
              data-toggle="tooltip"
              title="Cari"
            >
              <img src="/img/search.png" alt="search" width="25" />
            </button>
          </form>
        </div>
      </div>
      <div class="col-md-4 col-6 p-md-4">
        <div class="d-flex gap-2 justify-content-end">
          <button
            name="refresh"
            class="btn btn-light btn-sm mt-2"
            @click="refresh"
            data-toggle="tooltip"
            title="Refresh"
          >
            <img
              src="/img/refresh.png"
              alt="refresh"
              width="25"
              class="refresh"
            />
          </button>
          <button
            name="logout"
            class="btn btn-light btn-sm mt-2 d-none d-sm-block"
            @click="logout"
          >
            <img src="/img/logout.png" alt="logout" width="25" />
            <span class="p-1">Logout</span>
          </button>
        </div>
      </div>
    </div>

    <div style="min-height: 70vh">
      <table
        border="1"
        cellpadding="10"
        cellspacing="0"
        class="table table-bordered table-hover mt-4 shadow-lg font-size"
      >
        <thead>
          <tr class="bg-success text-center text-light">
            <th class="align-middle">No.</th>
            <th class="align-middle">Tanggal Surat</th>
            <th class="align-middle">Tanggal Entry</th>
            <th class="align-middle">Jenis Surat</th>
            <th class="align-middle">Asal Surat</th>
            <th class="align-middle">Keterangan</th>
            <th class="align-middle">Tag</th>
            <th class="align-middle">Dokumen</th>
            <th class="align-middle">Aksi</th>
          </tr>
        </thead>

        <tbody v-for="(row, idx) in surat" :key="idx">
          <tr class="text-center">
            <td class="align-middle">{{ idx + 1 }}</td>
            <td class="align-middle">{{ formatDate(row.tanggal_surat) }}</td>
            <td class="align-middle">{{ formatDate(row.tanggal_entry) }}</td>
            <td class="align-middle">{{ row.jenis_surat }}</td>
            <td class="align-middle">{{ row.asal_surat }}</td>
            <td class="align-middle">{{ row.keterangan }}</td>
            <td class="align-middle">{{ row.tag }}</td>
            <td class="align-middle">
              <router-link :to="'/update-data/' + row.id"
                >Lihat Surat</router-link
              >
            </td>
            <td class="align-middle">
              <div class="d-flex justify-content-center">
                <a @click="deleteData(row.id)"
                  ><img
                    src="/img/trash.png"
                    alt="delete"
                    class="pointer delete"
                    width="25"
                    data-toggle="tooltip"
                    title="Hapus"
                /></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Footer from "@/components/Footer.vue";

export default {
  name: "DashboardView",
  data() {
    return {
      surat: [],
      suratId: "",
      searchQuery: "",
    };
  },
  components: {
    Footer,
  },
  methods: {
    goToPage() {
      const isAuthenticated = localStorage.getItem("isAuthenticated");
      if (isAuthenticated !== "true") {
        alert("Anda belum login.");
        this.$router.push("/login");
      }
    },
    formatDate(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    fetchData() {
      axios
        .get("https://restfulapisurat.sayo.my.id/api/api_tampil_all.php")
        .then((res) => {
          this.surat = res.data;
        })
        .catch((err) => console.log(err));
    },
    deleteData(id) {
      const confirm = window.confirm("Anda yakin ingin menghapus surat ini?");

      if (confirm) {
        axios
          .delete(
            `https://restfulapisurat.sayo.my.id/api/api_hapus.php?id=${id}`
          )
          .then((response) => {
            if (response.data[0].status === "SUCCESS") {
              alert("Surat berhasil dihapus");
              this.fetchData();
            } else {
              alert("Gagal menghapus surat");
            }
          })
          .catch((error) => {
            console.log(error);
            alert("Surat gagal dihapus.");
          });
      }
    },
    searchData(e) {
      e.preventDefault();
      axios
        .get(
          `https://restfulapisurat.sayo.my.id/api/api_search.php?q=${this.searchQuery}`
        )
        .then((res) => {
          this.surat = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    refresh() {
      window.location.reload();
    },
    logout() {
      const confirm = window.confirm("Anda yakin ingin logout?");
      if (confirm) {
        localStorage.removeItem("isAuthenticated");
        this.$router.push("/login");
      }
    },
  },

  mounted() {
    this.goToPage();
    this.fetchData();
  },
};
</script>
